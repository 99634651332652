import { createApp, ref } from 'vue'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog, faArrowLeft, faTimes, faEdit} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCog)
library.add(faArrowLeft)
library.add(faTimes,faEdit)

const app = createApp(App)
app.component("font-awesome-icon",FontAwesomeIcon)
app.config.globalProperties.$apiIP = "http://127.0.0.1:8000/"
app.provide("token",ref(""))
app.mount("#app")
