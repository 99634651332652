<template>
    <div v-if="object.id != -1">
        <button v-if="token.value" @click="$emit('open-settings')"><font-awesome-icon icon="cog"/></button>
        <h3>{{object.name}}</h3>        
    </div>
    <div v-else style="display:block">
        <h2 style="text-align:center; margin-top:2em"> Zvolte objekt <br>
            <font-awesome-icon icon="arrow-left"/>
        </h2>
    </div>

    
</template>

<script>

export default{
    name: "ObjectHeader",
    props: {
        object: Object
    },
    inject: ["token"],
    emits : ["open-settings"]
}
</script>

<style scoped>
    div{
        display: flex;
        align-items: center;
        margin-top: 0;
        padding-top: 0;
    }
    h3{
        margin-right: auto;
        margin-left: auto;
        margin-top: 0;
    }
    button{
        margin-left: none;
        margin-top: 0;
        margin-bottom: auto;        
    }

</style>