<template>
    <object-header @open-settings="openSettings" :object="object"/> 
    <div>
    <div class="nastaveni" v-if="settingsAreOpened">
        <div class="nastaveniHeader">
            <h3>Nastavení</h3>
            <button @click="openSettings" style="margin-left:auto;margin-right:2em"><font-awesome-icon style="color:red" icon="times"/></button> 
        </div>
        <p>Změnit jméno:</p>
        <input v-model="newName" @keyup.enter="renameObject" type="text" placeholder="Nové jméno"/>
        <button @click="renameObject">Přejmenovat</button> <br>
        <p>Odstranit objekt:</p>
        <button @click="deleteObject" class="deleteButton">Odstranit objekt</button>
    </div>
    <div v-else-if="object.id !== -1" style="margin-top: -20px">
        <div class="calendarDiv" style="float:left">
            <Calendar :key="calendarKey" v-model="selectedDate" @dayclick="dayClicked" :attributes="calendarData" style="margin:0; margin-top: 1.5em" ref="calendar"/> <br>
            <input style="width: 98%; margin: 0; display: block" type="text" :placeholder="selectedDateText" v-model="selectedDateInput" />
        </div>
        <div v-if="selectedDateText == 'Datum (např.: 16.5.2021)' " style="padding-left: 11.5em" >
            <div style="float:left; display:flex; justify-content:middle">
                <h3 style="text-align:center;padding-top:1em;justify-self:flex-center">Zvolte datum <br>
                    <font-awesome-icon icon="arrow-left"/>
                </h3>
            </div>
        </div>
        <div v-else>
            <!--- Reservation DISPLAY -->
            <div v-if="currentReservation && !editing" style="padding-left: 11.5em"> 
                <div style="float:left">
                    <!-- reservation PICKER  -->
                    <div style="display:flex;justify-content:space-between;margin-bottom:0">
                        <button @click="reservationIndex -= 1" :disabled="reservationIndex > 0 ? false:true" style="margin-top:auto;margin-bottom:auto" >Předchozí</button>
                        <h5 >{{reservationIndex + 1}}/{{allReservations.length}}</h5>
                        <button @click="reservationIndex += 1" :disabled="reservationIndex < allReservations.length - 1 ? false:true" style="margin-top:auto;margin-bottom:auto">Následující</button>
                    </div>
                    <hr style="margin:0">
                    <h3 style="margin-bottom: 0px;margin-top:0px">{{currentReservationStart}} {{currentReservationEnd}} </h3>
                   
                    <h2 style="margin-top: 0px; margin-bottom: 0"> {{currentReservation.name}}</h2> <hr>
                    <h5 v-if="currentReservation.description" style="padding-top:0em; white-space: pre-wrap">{{currentReservation.description}} </h5>
                    <div v-if="token.value">
                        <button @click="editReservation" style="float:left; display: inline-block">Upravit</button> 
                        <button @click="deleteReservation" style="color:red; float:right; display: inline-block">Odstranit</button>
                        <button @click="createAnotherReservation" style=" float:center; margin-top:3em; margin-left:15%; font-size:large">Vytvořit další rezervaci</button>
                    </div>
                </div>
            </div>
            <div v-else-if="token.value" style="padding-left: 11.5em">
                <div style="float:left">
                    <button tabindex="1" title="Zahodit změny" @click="clearInputs" style="display: inline-block; float:right;margin-left:2em"> <font-awesome-icon style="color:red" icon="times"/></button>
                    <!-- Title -->
                    <div v-if="editing">
                        <h2 style="margin-top: 0px; margin-bottom: 0; display: inline-block">{{currentReservation.name}}</h2> <i style="display:inline-block">(upravování)</i>
                    </div>
                    <h2 v-else style="margin-top: 0px; margin-bottom: 0;display:inline-block" >Nová rezervace</h2>
                    <hr style="margin:0">
                    
                    <!-- Dates  -->
                    <table style="margin-top:0">
                        <tr style="margin:0">
                            <th style="margin-top:0"> <h6 style="margin-bottom:0">od: </h6> </th>
                            <th style="margin:0;vertical-align:bottom"> 
                                <input style="font-size:large;margin:0;display:block;" v-model="firstDateInput" @change="firstDateInputOnChange" />
                            </th>
                            
                            <th style="vertical-align:bottom;height: 1.1em"> <button style="height:2.1em" @click="onclickEditFirstDate"> <font-awesome-icon icon="edit"/></button> </th>
                        </tr>
                        <tr style="margin:0">
                            <th style="vertical-align: bottom; padding-bottom:5px"> <h6 style="margin:0">do: </h6>   </th>        
                            <th > 
                                <input style="font-size:large;margin:0" v-model="secondDateInput" @change="secondDateInputOnChange" :placeholder="secondDateText" />        
                            </th>
                            <th v-if="selectingSecondDate || secondDate.getTime() != 0" style="vertical-align:center;height: 1.1em;margin:0;padding-bottom:4px">
                                 <button style="height:2.1em;margin:0" @click="onclickEditSecondDate"> <font-awesome-icon icon="edit"/></button> 
                            </th>
                        </tr>
                    </table>
                    
                    <!-- reservation form -->
                    <p  style="display: inline-block">Název: </p>
                    <input type="text" v-model="newReservationName" style="width: 98%" placeholder="Název" />
                    <p>Poznámky: </p> <textarea v-model="newReservationDescription" rows="5" style="width: 98%; height: 5em"/>
                    <br>
                    <p style="display:inline">Opakovat: </p> 
                    <select v-model="repeatType">
                        <option value="0" selected>nikdy</option>
                        <option value="1">každý týden</option>
                        <option value="2">každý měsíc</option>
                    </select>
                    <br>
                    <button @click="createReservation" v-if="!editing" style="font-size:large; display: inline-block; background-color: seagreen; height:2rem; margin-top:1rem; color: #15201b;">Vytvořit rezervaci</button>
                    <button @click="saveEdits" v-else style="font-size:large; display: inline-block; color: seagreen; height:2rem; margin-top:1rem">Uložit změny</button>
                </div>
            </div>
            <!-- NOT LOGGED IN , NEW RESERVATION -->
            <div v-else style="padding-left: 11.5em"> 
                <div style="float:left">
                    <h3 style="margin-bottom: 0px">{{selectedDateText}} </h3>
                    <h2 style="margin-top: 0px; margin-bottom: 0"> Žádná rezervace</h2> <hr>
                    <p style="padding-top:0em; white-space: pre-wrap">Pro vytvoření nové rezervace kontaktujte <a href="https://www.velkytynec.cz/ilona-bartakova/o-1010/p1=7339" target="_blank">paní Bartákovou</a> </p>
                    <p>Tel. číslo: 585 151 111</p>
                    <p>email: sekretariat@velkytynec.cz</p>
                </div>
            </div>
        </div>
    
    </div>
    <Footer v-if="windowHeight > 500 && !token.value"/>
    </div> 

</template>


<script>
import ObjectHeader from "./ObjectHeader.vue"
import Footer from "./Footer.vue"
import {Calendar} from "v-calendar"

export default{
    name: "ObjectContent",
    components:{
        ObjectHeader,
        Calendar,
        Footer
    },
    inject: ["token"],
    props: {
        object: Object,
        'reservations': {}
    },
    watch: {
        'object.id': function(){
            this.settingsAreOpened = false
            this.selectedDate = new Date() 
            this.selectedDate.setTime(0)
            this.secondDate = new Date()
            this.secondDate.setTime(0)
            this.editedFirstDate = new Date()
            this.editedFirstDate.setTime(0)
            this.selectedDateInput = ""
            this.calendarKey++ //Resets the calendar to show the current date
            this.selectingSecondDate = false
            this.alerted = false
            this.firstDateInput = ""
            this.secondDateInput = ""
            this.creatingAnotherReservation = false
            this.reservationIndex = 0
        },
        firstDateInput: function(){
            
        },
        selectedDateInput: function(){ // Handles date inputed into the textbox
            var ISODate = this.convertTextToDate(this.selectedDateInput)
            if(!ISODate) return

            if(!this.selectingSecondDate){ // Save  
                var tempDate = ISODate
                this.selectedDate = tempDate
                this.firstDateInput = this.convertDateToText(this.selectedDate)
            }else{
                var tempSecondDate = ISODate
                if( tempSecondDate.getTime() >= this.selectedDate.getTime() ){
                    this.secondDate = tempSecondDate
                    
                }else{      // Second date is earlier then the first => switch them
                    if(!this.alerted){
                        alert("Konec vícedenní rezervace musí být později něž její začátek.\n(Přepnutí výběru lze provést kliknutím na první nebo druhé datum rezervace)")
                        this.alerted = true
                    }
                }
            }
            // Move the calendar to the given date
            const calendar = this.$refs.calendar
            calendar.move(this.selectedDate)
            this.reservationIndex = 0

            /*
            if(this.selectingSecondDate || this.secondDate.getTime() != 0){
                this.checkSelectedRange() // Check if there are any reservations in the selected range
            } */

            if(this.selectedDate.getTime() == this.secondDate.getTime()){
                this.selectingSecondDate = false
                this.secondDate = new Date()
                this.secondDate.setTime(0)
            }
        },
        selectedDate: function(){
            //if(this.secondDate.getTime() != 0) this.checkSelectedRange()
        },
        secondDate: function(){
            //if(this.secondDate.getTime() != 0) this.checkSelectedRange()
        }
    },
    data(){
        return{
            settingsAreOpened: false,
            newName: "",
            selectedDate: new Date(),
            secondDate: new Date(),
            editedFirstDate: new Date(),
            selectedDateInput: "",  // Needed ???
            firstDateInput: "",
            secondDateInput: "",
            calendarKey: 4200,
           // formKey: 200,
            newReservationName: "",
            newReservationDescription: "",
            selectingSecondDate: false,
            alerted : false, // Signals wheter alert about multiday reservation was shown
            editing: false,
            editingFirstDate: false,
            creatingAnotherReservation: false,
            windowHeight: window.innerHeight,
            reservationIndex: 0,
            repeatType: 0
        }
    },
    computed: {
        calendarData: function() {
            if(this.object.id && this.object.id != -1){ // Reservation data into calendar  
                var newCalendarData = []
                this.reservations.forEach(res => {
                    if(res.end){
                        let newHighligh = {
                            key: new Date(res.start).getTime()+res.id,
                            highlight: "red",
                            popover: {
                                label: this.convertDateToText(new Date(res.start)).slice(-5)+"-"+
                                this.convertDateToText(new Date(res.end)).slice(-5)+" " +res.name
                            },
                            dates: { start: new Date(res.start), end: new Date(res.end)}
                        }

                        let adding = new Date(res.start)
                        adding.setHours(0,0,0,0)
                        let end = new Date(res.end)
                        if(res.repeats && res.repeats == 1){ // Weekly
                            let dayNumbers = []
                            while(adding.getTime() <= end.getTime()){
                                dayNumbers.push(adding.getDay() + 1)
                                adding.setDate(adding.getDate() + 1)
                            }
                            newHighligh.dates = {weekdays:dayNumbers}
                        }else if(res.repeats && res.repeats == 2){ // Monthly
                            let dayNumbers = []
                            while(adding.getTime() <= end.getTime()){
                                dayNumbers.push(adding.getDate())
                                adding.setDate(adding.getDate() + 1)
                            }
                            newHighligh.dates = {days: dayNumbers}
                        }
                        newCalendarData.push(newHighligh)
                    }else{
                        newCalendarData.push({
                            key: res.id,
                            highlight: "red",
                            popover: {
                                label: res.name
                            },
                            dates: new Date(res.start)
                        })
                    }
                });
                if(this.selectedDate.getTime() != 0){  // Selected
                    var selectedHighlight = {
                        key: 100,
                        bar: "green",
                        dates: this.selectedDate
                    }
                    if(this.secondDate.getTime() != 0){
                        selectedHighlight.dates = {start: this.selectedDate, end: this.secondDate}
                    }
                    if(this.editedFirstDate.getTime() != 0){
                        selectedHighlight.dates.start = this.editedFirstDate
                    }
                    newCalendarData.push(selectedHighlight)
                }
                return newCalendarData
            } 
            return null
        },
        selectedDateText: function(){
            var txt = this.convertDateToText(this.selectedDate)
            if(txt === "") return "Datum (např.: 16.5.2021)"
            else return txt

        },
        secondDateText: function(){
            var txt = this.convertDateToText(this.secondDate)
            if(txt) return txt
            else return "Zvolte"
        },
        currentReservation: function(){ 
            if(!this.creatingAnotherReservation)
                return this.getReservationsForDate(this.selectedDate)[this.reservationIndex]
            else
                return undefined
        },
        allReservations: function(){
            return this.getReservationsForDate(this.selectedDate)
        },
        currentReservationStart: function(){
            if(!this.currentReservation.repeats || this.currentReservation.repeats == 0){
                return new Date(this.currentReservation.start).toLocaleString().slice(0,-3)
            }else if(this.currentReservation.repeats == 1){
                let date = new Date(this.currentReservation.start)
                let ret
                switch(date.getDay()){
                    case 1: ret = "Pondělí"; break;
                    case 2: ret = "Úterý"; break;
                    case 3: ret = "Středa"; break;
                    case 4: ret = "Čtvrtek"; break;
                    case 5: ret = "Pátek"; break;
                    case 6: ret = "Sobota"; break;
                    case 7: ret = "Neděle"; break;
                }
                ret += " " + date.toLocaleString().slice(-8,-3)
                return ret
            }else if(this.currentReservation.repeats == 2){
                let date = new Date(this.currentReservation.start)
                return date.getDate() +". "+ date.toLocaleString().slice(-8,-3)  
            }
            return new Date(this.currentReservation.start).toLocaleString().slice(0,-3)
        },
        currentReservationEnd: function(){
            if(this.currentReservation.end){
                if(!this.currentReservation.repeats || this.currentReservation.repeats == 0){
                    let sta = new Date(this.currentReservation.start)
                    let end = new Date(this.currentReservation.end)
                    if(sta.getDate()===end.getDate()&&sta.getMonth()===end.getMonth()&&sta.getFullYear()===end.getFullYear()){
                        return "- " + new Date(this.currentReservation.end).toLocaleTimeString().slice(0,5)
                    }else{
                        return "- " + new Date(this.currentReservation.end).toLocaleString().slice(0,-3)
                    }
                }else if(this.currentReservation.repeats == 1){
                    let date = new Date(this.currentReservation.end)
                    let ret = "- "
                    switch(date.getDay()){
                        case 1: ret += "Pondělí"; break;
                        case 2: ret += "Úterý"; break;
                        case 3: ret += "Středa"; break;
                        case 4: ret += "Čtvrtek"; break;
                        case 5: ret += "Pátek"; break;
                        case 6: ret += "Sobota"; break;
                        case 7: ret += "Neděle"; break;
                    }
                    ret += " " + date.toLocaleString().slice(-8,-3)
                    return ret
                }else if(this.currentReservation.repeats == 2){
                    let date = new Date(this.currentReservation.end)
                    return date.getDate() +". "+ date.toLocaleString().slice(-8,-3)  
                }
                return "- " + (new Date(this.currentReservation.end).toLocaleString().slice(0,-3))
            }
            else
                return ""
        }
    },

    mounted: function(){
      window.addEventListener('resize', () => {
          this.windowHeight = window.innerHeight
      })
    },
    methods: {
        getReservationsForDate(date){
            var resArray = Array.from(this.reservations)       
            var filtered = resArray.filter( res => {    
                if(!res.repeats || res.repeats == 0){
                    let start = new Date(res.start)
                    start.setHours(0,0,0,0)
                    let end = new Date(res.end)
                    end.setHours(23,59,59,59)
                    return (start <= date && date <= end)
                }else if(res.repeats == 1){ // weekly
                    let start = new Date(res.start)
                    start.setHours(0,0,0,0)
                    let end = new Date(res.end)
                    end.setHours(23,59,59,59)
                    while(start.getTime() <= date.getTime()){
                        if(start <= date && date <= end){
                            return true
                        } 
                        else{
                            start.setDate(start.getDate() + 7)
                            end.setDate(end.getDate() + 7)
                        }
                    }
                    return false
                }else if(res.repeats == 2){ // monthly
                    let start = new Date(res.start)
                    start.setHours(0,0,0,0)
                    let end = new Date(res.end)
                    end.setHours(23,59,59,59)
                    while(start.getTime() <= date.getTime()){
                        if(start <= date && date <= end){
                            return true
                        } 
                        else{
                            start.setMonth(start.getMonth() + 1)
                            end.setMonth(end.getMonth() + 1)
                        }
                    }
                    return false
                } 
            } )
            
            return filtered
        },
        checkSelectedRange(){ // returns true if there are no reservations
            var checkedDate = new Date()
            checkedDate.setTime( this.selectedDate.getTime() )
            while(checkedDate.getTime() <= this.secondDate.getTime() ){
                let dateReservations = this.getReservationsForDate(checkedDate)
                if(dateReservations != undefined ){
                    for(var i = 0; i < dateReservations.length; i++){
                        let start = new Date(dateReservations[i].start)
                        let end = new Date(dateReservations[i].end)
                        if(!dateReservations[i].repeats || dateReservations[i].repeats == 0){
                            if( ( start.getTime() <= this.selectedDate.getTime() && end.getTime() >= this.selectedDate.getTime() ) ||
                                (this.selectedDate.getTime() <= start.getTime() && this.secondDate.getTime() >= end.getTime()) ||
                                (start.getTime() >= this.selectedDate.getTime() && start.getTime() <= this.secondDate.getTime()) )
                            {
                                return false 
                            }
                        }
                        else if( dateReservations[i].repeats){
                            while(start.getTime() <= this.secondDate.getTime()){
                                if( ( start.getTime() <= this.selectedDate.getTime() && end.getTime() >= this.selectedDate.getTime() ) ||
                                    (this.selectedDate.getTime() <= start.getTime() && this.secondDate.getTime() >= end.getTime()) ||
                                    (start.getTime() >= this.selectedDate.getTime() && start.getTime() <= this.secondDate.getTime()) )
                                {
                                    return false
                                }else{
                                    if(dateReservations[i].repeats == 1){
                                        start.setDate(start.getDate() + 7)
                                        end.setDate(end.getDate() + 7)
                                    }else if(dateReservations[i].repeats == 2){
                                        start.setMonth(start.getMonth() + 1)
                                        end.setMonth(start.getMonth() + 1)
                                    }
                                }
                            }
                        }
                    }
                    checkedDate.setDate(checkedDate.getDate() + 1) // Adds a day to checked date
                }else{
                    checkedDate.setDate(checkedDate.getDate() + 1) // Adds a day to checked date
                }
            }
            return true
        },
        openSettings(){
            this.settingsAreOpened = !this.settingsAreOpened
        },
        renameObject(){
            if(this.newName.trim() != ""){
                var permission = confirm("Opravdu chcete přejmenovat "+this.object.name+" na: "+this.newName)
                if(permission) this.$emit("rename-object",this.newName)
            }
            this.newName = ""
        },
        deleteObject(){
            var first = confirm("Opravdu chcete odstranit "+this.object.name+" ? \n"+ "Budou odstraněny i veškeré rezervace spojené s tímto objektem.")
            if(first) var second = confirm("Potvrďtě smazání objektu "+this.object.name + "\nTato akce je NEVRATNÁ!")
            if(second) this.$emit("delete-object")
        },
        dayClicked(date){
            var tempDate = new Date(date.id)
            tempDate.setHours(0,0,0,0)
            this.reservationIndex = 0
            if(!this.selectingSecondDate){ // selecting first Date (EDITING)
                if(!this.editingFirstDate && this.editing) this.abortEdits() // Stop editing (also sets selected Date)
                this.creatingAnotherReservation = false
                if(this.editingFirstDate && this.editing){ // Only change first date of reservation DONT CHANGE SELECTED DATE
                    if( (this.secondDate.getFullYear() === this.selectedDate.getFullYear() && this.secondDate.getMonth() === this.selectedDate.getMonth() && this.secondDate.getDate() == this.selectedDate.getDate() )
                        ||  tempDate.getTime() <= this.secondDate.getTime() ){
                        this.editedFirstDate = tempDate
                        this.firstDateInput = this.convertDateToText(this.editedFirstDate)
                    }else{
                        alert("Konec vícedenní rezervace musí být později něž její začátek.")
                    }
                }else{ // Set Selected date
                    if( (this.secondDate.getFullYear() === this.selectedDate.getFullYear() && this.secondDate.getMonth() === this.selectedDate.getMonth() && this.secondDate.getDate() == this.selectedDate.getDate() ) 
                        || tempDate.getTime() > this.secondDate.getTime() ){
                        this.selectedDate = tempDate
                       // this.secondDate = new Date(tempDate)
                        this.secondDate.setTime(this.selectedDate.getTime())
                        this.secondDate.setHours(23,59,0,0)
                        this.firstDateInput = this.convertDateToText(this.selectedDate)
                        this.secondDateInput = this.convertDateToText(this.secondDate)
                    }else if(tempDate.getTime() <= this.secondDate.getTime()){
                        this.selectedDate = tempDate
                        this.firstDateInput = this.convertDateToText(this.selectedDate)
                    }
                }
            }else{ // Selecting SECOND date
                if(tempDate.getTime() >= this.selectedDate.getTime() ){
                    this.secondDate = tempDate
                    this.secondDate.setHours(23,59,0,0)
                    this.secondDateInput = this.convertDateToText(this.secondDate)
                }else{
                    alert("Konec vícedenní rezervace musí být později něž její začátek.")
                }
            }

            if(this.selectedDate.getTime() == this.secondDate.getTime()){
                this.selectingSecondDate = false
                this.secondDate = new Date()
                this.secondDate.setTime(0)
            }
            //this.selectedDateInput = ""
        },
        convertDateToText(date){
            if(date.getTime() != 0){
                var day = date.getDate()
                var month = date.getMonth() +1  // TODO : Check if months are correct
                var year = date.getFullYear()
                var time = date.toTimeString().slice(0,5)
                return day + "." + month + "." + year + " " + time
            }else{
                return ""
            }  
        },
        convertTextToDate(dateTimeText){ // Converts date in czech format (DD.MM.YYYY) to Date object   
            var dateText = dateTimeText.split(' ')[0]
            var timeText = dateTimeText.split(' ')[1]
            var dateParts = dateText.split('.') 
            var ISODate = ""
            if(dateParts.length > 1 && dateParts[1] != ""){   // Convert from DD.MM.YYYY to YYYY-MM-DD (ISO Format)
                if(dateParts.length == 3 && dateParts[2] != ""){ // DD.MM.YYYY
                    ISODate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]
                    if(timeText) ISODate += " " + timeText
                }else{   // DD.MM.
                    ISODate = new Date().getFullYear().toString() + "-" + dateParts[1] + "-" + dateParts[0]
                    if(timeText) ISODate += " " + timeText
                }
                var result = new Date(ISODate)
                if(isNaN(result.getTime()) || result.getTime() == 0) return null
                return result
            }
            return null
        },
        selectSecondDate(){
            this.selectingSecondDate = true
        },
        clearInputs(){
            this.newReservationName = ""
            this.newReservationDescription = ""
            this.selectingSecondDate = false
            this.secondDate = new Date()
            this.secondDate.setTime(0)
            this.selectedDate = new Date()
            this.selectedDate.setTime(0)
            this.calendarKey++  
            if(this.editing) this.abortEdits()
            this.editedFirstDate.setTime(0)
            this.creatingAnotherReservation = false
            this.repeatType = 0
        },
        createReservation(){
            if( !this.newReservationName ){
                alert("Nová rezervace musí mít název.")
                return
            }
            if(this.checkSelectedRange() == false){
                let override = confirm("V zadaném období již existuje rezervace! \n I přesto vytvořit další ?")
                if(!override) return   
            }
            if(this.secondDate.getTime() - this.selectedDate.getTime() < 1000){ // Time range is samller than 1 second
                alert("Časové rozmezí je příliš malé.")
                return
            }
            var res = {
                id: -1,
                objectId: this.object.id,
                start: this.selectedDate.toISOString(),
                end: !isNaN(this.secondDate.getTime()) && this.secondDate.getTime() != 0 ? this.secondDate.toISOString() : "",
                name: this.newReservationName,
                description: this.newReservationDescription,
                repeats: this.repeatType  // 0 = dont repeat  1 = repeat weekly  2 = repeat monthly
            }
            this.selectingSecondDate = false
            this.secondDate.setTime(0) // reset
            this.repeatType = 0
            this.newReservationName = ""
            this.newReservationDescription = ""
            if(this.creatingAnotherReservation) this.creatingAnotherReservation = false
            this.$emit("create-reservation",res)
        },
        deleteReservation(){
            var confirmation = confirm("Opravdu chcete odstranit rezervaci " + this.currentReservation.name + " ? \n")
            if(confirmation){
                this.$emit('delete-reservation',this.currentReservation.id)
            }
        },
        editReservation(){
            this.editing = true
            
            var start = new Date(this.currentReservation.start)
            this.selectedDate.setTime(start.getTime())
            this.firstDateInput = this.convertDateToText(this.selectedDate)
            
            var end = new Date(this.currentReservation.end)
            this.secondDate.setTime(end.getTime())
            this.secondDateInput = this.convertDateToText(this.secondDate)
            
            this.repeatType = this.currentReservation.repeats
            
            this.newReservationName = this.currentReservation.name
            this.newReservationDescription = this.currentReservation.description
        },
        saveEdits(){
            var edited = {
                //id: this.currentReservation.id,
                //objectId: this.object.id,
                start: this.currentReservation.start,
                end: this.currentReservation.end,
                name: this.newReservationName,
                description: this.newReservationDescription,
                repeats: this.repeatType
            }
            if(this.editedFirstDate.getTime() != 0){
                edited.start = this.editedFirstDate.toISOString()
            }

            if(this.secondDate.getTime() != 0 && this.secondDate.getTime() != new Date(this.currentReservation.start).getTime() ){
                edited.end = this.secondDate.toISOString()
            }else{
                edited.end = ""
            }

            this.$emit("edit-reservation",edited,this.currentReservation.id)
            this.selectedDate = new Date()
            this.selectedDate.setTime(0) //this.editedFirstDate.getTime() )
            this.repeatType = 0
            this.abortEdits()
        },
        abortEdits(){ // Exits editing mode
            this.editing = false
            this.newReservationName = ""
            this.newReservationDescription = ""
            this.selectingSecondDate = false
            this.editingFirstDate = false
            this.secondDate.setTime(0)
            this.editedFirstDate.setTime(0)
            this.repeatType = 0

        },
        firstDateInputOnChange(){
            var date = this.convertTextToDate(this.firstDateInput)
            if(!this.editing){
                if(date) this.selectedDate = date
            }else{ // editing
                this.editingFirstDate = true
                this.editedFirstDate = date
            }
        },
        secondDateInputOnChange(){
            var date = this.convertTextToDate(this.secondDateInput)
            if(date) this.secondDate = date
            if(this.editingFirstDate) this.editingFirstDate = false
        },
        onclickEditFirstDate(){
            this.selectingSecondDate = false
            if(this.editing) this.editingFirstDate = true
        },
        onclickEditSecondDate(){
            this.selectingSecondDate = true
            if(this.editingFirstDate) this.editingFirstDate = false
        },
        createAnotherReservation(){
            this.creatingAnotherReservation = true
        },
    },
    emits: ["rename-object","delete-object","create-reservation","delete-reservation","edit-reservation"]
}
</script>

<style scoped>

    div.nastaveniHeader{
        display: flex;
        align-items: center;
    }
    div.nastaveni{
        margin-left:2em
    }
    p{
        margin-bottom: 0em;
        margin-top: 0em
    }

    button.deleteButton{
        color: red;
    }

    .dateHeader{
        margin-bottom: 0px; 
        display:inline-block
    }

    .dateHeader:hover{
        cursor: pointer
    }
  

</style>