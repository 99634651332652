<template>
    <button v-if="!token.value" @click="startLogin">Přihlásit se</button>
    <button v-else @click="logout" >Odhlásit se</button>
</template>


<script>
export default{
    name: "Login",
    emmits: ["got-token","delete-token"],
    data(){
        return{
            
        }
    },
    inject: ["token"],
    mounted: function(){  
        let savedToken = localStorage.getItem("accessToken")
        if(savedToken != null && savedToken != ""){
            this.token.value = savedToken
        }
    },
    methods: {
        startLogin(){
            let pass = prompt("Zadejte heslo")
            let vm = this
            fetch(this.$apiIP+"login?password="+pass,{
                method: "POST"
            }).then(function(response) {
                if(response.ok)
                    return response.json()
                else
                    alert("Nesprávné heslo")
            }).then(function (data) {
                vm.token.value = data.access_token
                localStorage.setItem("accessToken",data.access_token)
                //vm.$emit("got-token",vm.token)
            })
        },
        logout(){
            this.token.value = ""
            localStorage.removeItem("accessToken")
        }
    }
}

</script>

<style scoped>
    button{
        position: absolute;
        top: 0;
        right: 0;
        margin:0.5em
    }

</style>

