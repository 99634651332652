<template>
  <div class="object-list">
    <object-list  :objects="objects" @select-object="onSelectObject" @add-object="onAddObject" :selected="selectedObject"/>
  </div>
  <div class="content">
    <object-content :object="selectedObject" :reservations="selectedReservations" @create-reservation="createReservation" @delete-reservation="deleteReservation" 
      @edit-reservation="editReservation" @rename-object="renameObject" @delete-object="deleteObject"/>
  </div>
  <Login />
</template>

<script>
import ObjectContent from './components/ObjectContent.vue'
import ObjectList from "./components/ObjectList.vue"
import Login from "./components/Login.vue"


export default{
  name: 'App',
  components: {
    ObjectList,
    ObjectContent,
    Login
  },
  inject: ["token"],
  data(){
    return {
      objects: Array,
      reservations : Array,
      selectedReservations: Array,
      /*selectedReservations:{ 
        type: Array,
        default: function (){
          return []
        }
      }, */
      selectedObject: {},
    }
  },
  mounted: function(){  // Is performed on page load
    this.selectedObject = {
      id: -1,
      name: ""
    }
  },
  methods: {
    onSelectObject(obj){
      this.selectedObject.name = obj.name
      this.selectedObject.id = obj.id 
      this.fetchReservations()
    },
    onAddObject(){ 
      var newName = prompt("Zadejte název nového objektu.","")

      if(newName && newName.trim() !== ""){
        let vm = this
        fetch(this.$apiIP+"object?token="+this.token.value,{
                method: "POST",
                body: '{"name":"'+newName+'"}',
                headers: {"Content-type": "application/json"}
            }).then(function(response) {
              if(response.ok)
                vm.fetchObjects()
              else
                alert("Chyba")
            })
      }
    },
    renameObject(newName){   
      this.selectedObject.name = newName
      if(newName && newName.trim() !== ""){
        let vm = this
        fetch(this.$apiIP+"object/"+this.selectedObject.id+"?token="+this.token.value,{
          method: "PUT",
          body: '{"name":"'+newName+'"}',
          headers: {"Content-type": "application/json"}
        }).then(function(response){
          if(response.ok)
            vm.fetchObjects()
          else
            alert("Chyba")
        })
      }
    },
    deleteObject(){
      let vm = this
      fetch(this.$apiIP+"object/"+this.selectedObject.id+"?token="+this.token.value,{
          method: "DELETE",
          headers: {"Content-type": "application/json"}
        }).then(function(response){
          if(response.ok)
            vm.fetchObjects()
          else
            alert("Chyba")
        })

      this.selectedObject.id = -1
      this.selectedObject.name = ""
    },
    createReservation(reservation){
      let vm = this
      fetch(this.$apiIP+"reservation?token="+this.token.value,{
                method: "POST",
                body: JSON.stringify(reservation),
                headers: {"Content-type": "application/json"}
            }).then(function(response) {
              if(response.ok)
                vm.fetchReservations()
              else
                alert("Chyba")
        })  
    },
    deleteReservation(reservationId){
      let vm = this
      fetch(this.$apiIP+"reservation/"+reservationId+"?token="+this.token.value,{
          method: "DELETE",
          headers: {"Content-type": "application/json"}
        }).then(function(response){
          if(response.ok)
            vm.fetchReservations()
          else
            alert("Chyba")
        })
    },
    editReservation(edited,id){
      let vm = this
        fetch(this.$apiIP+"reservation/"+id+"?token="+this.token.value,{
          method: "PUT",
          body: JSON.stringify(edited),
          headers: {"Content-type": "application/json",
            'Access-Control-Allow-Origin': '*'}
        }).then(function(response){
          if(response.ok)
            vm.fetchReservations()
          else
            alert("Chyba")
        })
    },
    fetchObjects(){
      this.objects = [];
      var vm = this
      fetch(this.$apiIP+"object").then(function(response) {
        return response.json()
      }).then(function (data) {
        vm.objects = data
      })
    },
    fetchReservations(){ // Gets reservations of currently selected object from server
      let vm = this
      this.selectedReservations = []
      fetch(this.$apiIP+"reservation/"+this.selectedObject.id).then(function(response) {
        return response.json()
      }).then(function (data) {
        vm.selectedReservations = data.sort( (a,b) => {
                let aDate = new Date(a.start)
                let bDate = new Date(b.start)
                if(aDate.getTime() <= bDate.getTime()) return -1
                else return 1
            })
      })
    }
  },
  created() {
    this.fetchObjects()
    this.selectedReservations = []
  }
}
</script>

<style>
 body{
   font-size: 1.5em;
 }
</style>

<style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
  *{
    font-family: "Poppins",sans-serif;
  }

  .object-list{
    width:20%;
    height: 100%;
    background-color: none;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    border-right: ridge darkslategray 2px;
    margin-right: 0px;
  }

  .content{
    margin-left:20%;
    padding-left: 1em;
  }

</style>
