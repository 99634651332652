<template>
    <input v-model="search" @change="handleSearch" type="text" placeholder="Vyhledat &#128269;" />
    <div :class="token.value?'object-list-container-logged':'object-list-container-notlogged'">
        <div class="object-list" :key="obj.id" v-for="obj in shownObjects">  
            <object-list-button @select-object="$emit('select-object',obj)" :object="obj" :selected="selected" />
        </div>
    </div>
    <button v-if="token.value" @click="$emit('add-object')" class="add-object d-md-none d-lg-block">Přidat nový objekt</button>
    
</template>

<script>
import ObjectListButton from './ObjectListButton.vue'

export default{
    name: "ObjectList",
    components: {
        ObjectListButton
    },
    inject: ["token"],
    props: {
        objects: Array,
        selected: {}
    },
    watch: {
        objects: function(){
            this.shownObjects = this.objects;
            this.shownObjects.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0 ))
        },
        search: function(){
            if(this.search && this.search.trim() !== ""){
                this.shownObjects = this.shownObjects.filter( obj => obj.name.toLowerCase().includes(this.search.toLowerCase()) )
            }else{
                this.shownObjects = this.objects
            }
            this.shownObjects.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0 ))
        }
    },
    emits: ["select-object","add-object"],
    data() {
        return {
            search: '',
            shownObjects: this.objects
        }
    },
    created(){
        this.shownObjects.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0 ))
    }

}
</script>

<style scoped>
    input{
        width: 100%;
        margin-bottom: 0.4em;
        box-sizing: border-box;
    }
    .object-list{
       
    }

    .add-object{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 5%;
    }
    .object-list-container-logged{
     /*    height: calc(100% - 35px); */
        height: 85%;
        overflow-y:auto;
    }
        .object-list-container-notlogged{
     /*    height: calc(100% - 35px); */
        height: 100%;
        overflow-y:auto;
    }

</style>
