<template>  
    <div @click="$emit('select-object',object)" :class="(object.id === selected.id)?'selected':'not-selected'">{{ object.name }}</div>
</template>

<script>

  export default {
      name: "ObjectListButton",
      props: {
          object: {},
          selected: {}
      },
      emits: ["select-object"],
      watch: {
        object: function(){
          console.log("ObjectButton update new name : ",this.object.name)
        }
      }

    }
</script>

<style scoped>
button {
  width:100%
}
div{
  margin-top: -0.2em;
  width: 100%;
  text-align: center;
  line-height: 1.3;
  margin-top: 0.3em

}
div.not-selected{
  background-color: none;
}
div:hover{
 /* background-color: lightseagreen; */
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: seagreen;
}
div.selected{
 /* background-color: steelblue; */
 color: seagreen;
 font-weight: bold
}
.not-selected + .not-selected{
  border-top:  none;
}


</style>
