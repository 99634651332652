<template>
    <div>
        <p style="margin-left:1em">Pro vytvoření rezervace kontaktujte</p> <a href="https://www.velkytynec.cz/ilona-bartakova/o-1010/p1=7339" title="Kontaktní informace" target="_blank" rel="noopener noreferrer">paní Bartákovou</a>
    </div>
</template>

<script>

export default{
    name: "Footer",
}

</script>

<style scoped>
    div{
        width: 80%;
        background-color: seagreen;
        margin:0;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left:20%;
        text-align: center;
        padding: 0px;
    }
    p{
        display: inline-block;
        font-size: smaller;
        color: gainsboro;
        margin:0.5em
    }
    p.nebo{
        display: inline-block;
        font-size: smaller;
        color: #dcdcdc73
    }
    a{
        font-size: smaller;
        color: #bdf78e;
        text-decoration: underline;
    }
    a.nebo{
        color:#bdf78e7c
    }
    a:hover{
        color:aliceblue;
        cursor: pointer
    }
</style>
